import React from "react";
import { Link } from "gatsby";

export default function Footer() {
  return (
    <>
      <footer
        id="footer"
        className="bg-freelyBlack text-white py-4  px-6 lg:py-5 "
      >
        <div className="container mx-auto  flex  flex-col items-center justify-center lg:flex-row">
          <section className=" ">
            <p>©2020 Freely Health. All rights reserved.</p>
          </section>
          <section className="hidden pl-6 lg:block">|</section>
          <section className="flex  pt-2 lg:pt-0">
            <Link to="/privacy/" className="privacy-policy px-6 ">
              Privacy Policy
            </Link>{" "}
            |
            <Link to="/terms" className="privacy-policy px-6 ">
              Terms of Use
            </Link>
          </section>
        </div>
      </footer>
    </>
  );
}
